import Vue from 'vue'
import './plugins/vuex'
import './plugins/vue-router'
import './plugins/element'
import './plugins/vue-js-modal'
import './plugins/fontawesome'
import './plugins/tinymce'
import './plugins/vee-validate'
import './plugins/imageEditor'
import './plugins/ui-elements'
import './plugins/highcharts'
import './plugins/moments'
import './plugins/numeral'

// Global Mixin
import './mixin'
// Style Global
import '@/scss/index.scss' // global css

import App from './App'
import store from './store'
import router from './router/index'

import '@/icons' // icon
import '@/permission' // permission control
import HighchartsVue from 'highcharts-vue'
Vue.config.productionTip = false
Vue.use(HighchartsVue)
Vue.use(require('moment'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$urlGlobalApi = 'https://torniquete.webdecero.dev/'
// Vue.prototype.$urlGlobalApi = 'https://api.ccnatacion.com/'
