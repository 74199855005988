import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/layout'

Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [

  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/',
    redirect: '/dashboard',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: { title: 'Dashboard', icon: 'el-icon-s-home' }
      }
    ]
  }
  // { path: '*', component: Layout, redirect: '/dashboard', hidden: true }
]

export const asyncRoutes = [
  {
    path: '/billing',
    component: Layout,
    name: 'billing',
    meta: { title: 'Facturación', icon: 'el-icon-s-ticket', scopes: ['transactions_jr', 'transactions_sh', 'log_transactions'] },
    children: [
      {
        path: '/jackrabbit',
        name: 'transaction.jackrabbit',
        component: () => import('@/views/transactionsJR/index'),
        meta: { title: 'Tickets JackRabbit', icon: 'el-icon-notebook-1', scopes: ['transactions_jr'] }
      },
      {
        path: '/shopify',
        name: 'shopify',
        component: () => import('@/views/transactionsShopify/index'),
        meta: { title: 'Tickets Shopify', icon: 'el-icon-notebook-2', scopes: ['transactions_sh'] }
      },
      {
        path: '/log',
        name: 'log',
        component: () => import('@/views/logTransactions/index'),
        meta: { title: 'Log Facturación', icon: 'el-icon-collection', scopes: ['log_transactions'] }
      },
      {
        path: '/productos',
        name: 'shopify.product',
        component: () => import('@/views/shopifyProducts/index'),
        meta: { title: 'Productos', icon: 'el-icon-potato-strips', scopes: ['log_transactions'] }
      },
      {
        path: 'crear',
        name: 'shopify.product.create',
        component: () => import('@/views/shopifyProducts/create'),
        meta: {
          title: 'Crear producto',
          scopes: ['log_transactions']
        },
        hidden: true
      },
      {
        path: 'editar',
        name: 'shopify.product.edit',
        component: () => import('@/views/shopifyProducts/edit'),
        meta: {
          title: 'Editar producto',
          scopes: ['log_transactions']
        },
        hidden: true
      },
      {
        path: 'corregir',
        name: 'shopify.product.fix',
        component: () => import('@/views/shopifyProducts/fix'),
        meta: {
          title: 'Corregir producto',
          scopes: ['log_transactions']
        },
        hidden: true
      }
    ]
  },
  /* {
    path: '/campaigns',
    component: Layout,
    name: 'campaigns',
    meta: { title: 'Whatsapp', icon: 'el-icon-s-comment', scopes: ['campaings'] },
    children: [
      {
        path: '/campaings',
        name: 'campaign.show',
        component: () => import('@/views/campaign/index'),
        meta: { title: 'Registro Campañas', icon: 'el-icon-s-flag' }
      },
      {
        path: '/templates',
        name: 'campaign.create',
        component: () => import('@/views/campaign/create'),
        meta: { title: 'Plantillas', icon: 'el-icon-circle-plus' }
      },
      {
        path: '/edit',
        name: 'campaign.create',
        component: () => import('@/views/campaign/edit'),
        meta: { title: 'Configuracion', icon: 'el-icon-edit-outline' }
      }
    ]
  }, */
  /* {
    path: '/asistencias',
    component: Layout,
    meta: {
      scopes: ['assists']
    },
    children: [
      {
        path: '',
        name: 'assistances',
        component: () => import('@/views/assistances/index'),
        meta: { title: 'Asistencias', icon: 'el-icon-s-claim' }
      }
    ]
  },
  {
    path: '/ausencia',
    component: Layout,
    meta: {
      scopes: ['absences']
    },
    children: [
      {
        path: '',
        name: 'absences',
        component: () => import('@/views/absences/index'),
        meta: { title: 'Ausencias', icon: 'el-icon-s-release' }
      }
    ]
  }, */
  {
    path: '/test-class',
    component: Layout,
    name: 'test-class',
    meta: { title: 'Clase prueba', icon: 'el-icon-s-shop', scopes: ['leads', 'exstudents', 'orders_conekta'] },
    children: [
      {
        path: '/leads',
        name: 'leads',
        component: () => import('@/views/leads/index'),
        meta: { title: 'Leads', icon: 'el-icon-tickets', scopes: ['leads'] }
      },
      {
        path: '/ex-students',
        name: 'ex-students',
        component: () => import('@/views/exStudents/index'),
        meta: { title: 'Ex alumnos', icon: 'el-icon-document', scopes: ['exstudents'] }
      },
      {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/ordersConekta/index'),
        meta: { title: 'Pagos clase', icon: 'el-icon-bank-card', scopes: ['orders_conekta'] }
      }
    ]
  },
  {
    path: '/payments',
    component: Layout,
    meta: {
      scopes: ['payments_jr']
    },
    children: [
      {
        path: '',
        name: 'payments',
        component: () => import('@/views/paymentsJR/index'),
        meta: { title: 'Pagos Conekta', icon: 'el-icon-bank-card' }
      }
    ]
  },
  /* {
    path: '/mit',
    component: Layout,
    name: 'mit',
    meta: { title: 'Pagos MIT', icon: 'el-icon-s-finance', scopes: ['webpayments'] },
    children: [
      {
        path: '/webpay',
        name: 'webpay',
        component: () => import('@/views/webpayMIT/index'),
        meta: { title: 'Pagos', icon: 'el-icon-bank-card', scopes: ['webpayments'] }
      },
      {
        path: '/webpay-orders',
        name: 'webpay-orders',
        component: () => import('@/views/webpayOrder/index'),
        meta: { title: 'Transacciones', icon: 'el-icon-tickets', scopes: ['webpayments'] }
      }
    ]
  }, */
  {
    path: '/automation-payments',
    component: Layout,
    name: 'automation-payments',
    meta: {
      title: 'Pagos Auto.',
      icon: 'el-icon-bank-card',
      scopes: ['payment_automation']
    },
    children: [
      {
        path: 'payments-store',
        name: 'payments-store',
        component: () => import('@/views/automationPayment/paymentsStore/index'),
        meta: { title: 'Pagos Store', icon: 'el-icon-bank-card' }
      },
      {
        path: 'prosa',
        name: 'prosa',
        component: () => import('@/views/automationPayment/prosa/index'),
        meta: { title: 'Prosa', icon: 'el-icon-bank-card' }
      },
      {
        path: 'prosa/info',
        name: 'prosa-info',
        component: () => import('@/views/automationPayment/prosa/info'),
        hidden: true
      },
      {
        path: 'prosa/process',
        name: 'prosa-process',
        component: () => import('@/views/automationPayment/prosa/process'),
        hidden: true
      }
    ]
  },
  {
    path: '/biometrics',
    component: Layout,
    name: 'biometrics',
    meta: {
      title: 'Bio Accesos',
      icon: 'el-icon-key',
      scopes: ['assists']
    },
    children: [
      {
        path: '/biosubject/dashboard',
        name: 'biometrics.biosubject.dashboard',
        component: () => import('@/views/bioDashboard/index'),
        meta: { title: 'Dashboard', icon: 'el-icon-data-line' }
      },
      {
        path: '/biosubject',
        name: 'biometrics.biosubject',
        component: () => import('@/views/bioSubject/index'),
        meta: { title: 'Usuarios', icon: 'el-icon-s-custom' }
      },
      {
        path: '/biosubject/edit',
        name: 'biometrics.biosubject.edit',
        component: () => import('@/views/bioSubject/edit'),
        meta: { },
        hidden: true
      },
      {
        path: '/biofingerprint',
        name: 'biometrics.biofingerprint',
        component: () => import('@/views/bioFingerprint/index'),
        meta: { title: 'Huellas', icon: 'el-icon-thumb' }
      },
      {
        path: '/bioaccess/',
        name: 'biometrics.bioaccess.id',
        component: () => import('@/views/bioAccess/index'),
        meta: { title: 'Accesos', icon: 'el-icon-unlock' }
      },
      {
        path: '/bioevents',
        name: 'biometrics.bioevents',
        component: () => import('@/views/bioEvents/index'),
        meta: { title: 'Eventos', icon: 'el-icon-finished' }
      },
      {
        path: '/monitoring-access',
        name: 'biometrics.monitoringAccess',
        component: () => import('@/views/monitoring/index'),
        meta: { title: 'Monitoreo de Accesos', icon: 'el-icon-aim' }
      }
    ]
  },
  {
    path: '/locations',
    component: Layout,
    name: 'locations',
    meta: {
      icon: 'el-icon-office-building',
      title: 'Bio Company',
      scopes: ['assists']
    },
    children: [
      {
        path: '',
        name: 'locations.index',
        component: () => import('@/views/bioLocation/index'),
        meta: {
          title: 'Sucursales',
          icon: 'el-icon-map-location'
        }
      },
      {
        path: '/locations/create',
        name: 'locations.create',
        component: () => import('@/views/bioLocation/create'),
        meta: {
        },
        hidden: true
      },
      {
        path: '/locations/edit',
        name: 'locations.edit',
        component: () => import('@/views/bioLocation/edit'),
        meta: {
        },
        hidden: true
      },
      {
        path: '/terminals/',
        component: () => import('@/views/bioTerminals/index'),
        name: 'locations.terminals',
        meta: {
          icon: 'el-icon-mobile',
          title: 'Terminales'
        }
      },
      {
        path: '/terminals/create',
        name: 'locations.terminals.create',
        component: () => import('@/views/bioTerminals/create'),
        meta: {
        },
        hidden: true
      },
      {
        path: '/terminals/edit',
        name: 'locations.terminals.edit',
        component: () => import('@/views/bioTerminals/edit'),
        meta: {
        },
        hidden: true
      },
      {
        path: '/licenses/',
        component: () => import('@/views/bioLicenses/index'),
        name: 'locations.terminals.licenses',
        meta: {
          icon: 'el-icon-key',
          title: 'Licencias'
        }
      },
      {
        path: '/licenses/create',
        name: 'locations.terminals.licenses.create',
        component: () => import('@/views/bioLicenses/create'),
        meta: {
        },
        hidden: true
      },
      {
        path: '/licenses/edit',
        name: 'locations.terminals.licenses.edit',
        component: () => import('@/views/bioLicenses/edit'),
        meta: {
        },
        hidden: true
      }
    ]
  },
  {
    path: '/admin',
    component: Layout,
    meta: {
      scopes: ['users']
    },
    children: [
      {
        path: '',
        name: 'admin',
        component: () => import('@/views/admin/index'),
        meta: { title: 'Administradores', icon: 'el-icon-s-custom' }
      },
      {
        path: 'edit/:_id',
        name: 'admin.edit',
        component: () => import('@/views/admin/edit'),
        meta: {
          title: 'Editar Admin'
        },
        hidden: true
      },
      {
        path: 'create',
        name: 'admin.create',
        component: () => import('@/views/admin/create'),
        meta: {
          title: 'Crear Admin'
        },
        hidden: true
      }
    ]
  },
  { path: '*', component: Layout, redirect: '/dashboard', hidden: true }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes

})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
