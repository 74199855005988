import { search as getAvailableLocations } from '@/api/bioLocations.js'

const setMetadata = (objectEvent) => {
  objectEvent.metadataKeyValue = []
  for (const key in objectEvent.data) {
    objectEvent.metadataKeyValue.push({
      keyObj: key,
      valueObj: objectEvent.data[key]
    })
  }
}

const state = {
  accessItem: {},
  locationsModules: {},
  locationsReference: []
}

const mutations = {
  setItem (state, payload) {
    setMetadata(payload)
    state.accessItem = payload
    console.log(state.accessItem)
  },
  setLocationModule (state, property) {
    state.locationsModules[`${property}`] = []
    state.locationsReference.push(property)
  },
  setItemToCollection (state, item) {
    if (state.locationsReference.includes(`${item.bioLocationKey}`)) {
      setMetadata(item)
      console.log(item.bioLocationKey)
      if (state.locationsModules[`${item.bioLocationKey}`].length <= 10) {
        state.locationsModules[`${item.bioLocationKey}`].unshift(item)
      } else {
        state.locationsModules[`${item.bioLocationKey}`].pop()
        state.locationsModules[`${item.bioLocationKey}`].unshift(item)
      }
    }
  },
  clearSocket (state) {
    state.locationsReference.forEach(location => {
      state.locationsModules[`${location}`] = []
    })
  }
}

const actions = {
  setSocketItem ({ commit }, payload) {
    commit('setItem', payload)
  },
  async setLocations ({ commit }) {
    await getAvailableLocations({ query: '' })
      .then((response) => {
        const locationsList = response.data.data
        locationsList.forEach(location => {
          console.log(location.key)
          commit('setLocationModule', location.key)
        })
      })
      .catch(this.responseCatch)
      .finally(() => (this.loading = false))
  },
  setItemToLocationModule ({ commit }, payload) {
    commit('setItemToCollection', payload)
  },
  clearSocketStore ({ commit }) {
    commit('clearSocket')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
