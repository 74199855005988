import request from '@/utils/request-biometric'
export function search (params) {
  return request({
    url: '/bio-terminal/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/bio-terminal/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function createTerminal (data) {
  return request({
    url: 'bio-terminal',
    method: 'post',
    data
  })
}

export function getTerminal (id) {
  return request({
    url: `bio-terminal/${id}`,
    method: 'get'
  })
}

export function updateTerminal (data, id) {
  return request({
    url: `bio-terminal/${id}`,
    method: 'put',
    data
  })
}

export function deleteTerminal (id) {
  return request({
    url: `bio-terminal/${id}`,
    method: 'delete'
  })
}

export function validateTerminalKey (keyTerminal, config) {
  return request({
    url: 'bio-terminal/key/verify',
    method: 'post',
    params: {
      key: keyTerminal
    }
  })
}

export function exportExcel (data) {
  return request({
    url: 'bio-terminal/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
