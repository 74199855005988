var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor"},[_c('div',{staticClass:"canvas",on:{"dblclick":_vm.dblclick}},[_c('img',{ref:"image",attrs:{"alt":_vm.data.name,"src":_vm.data.url},on:{"loadstart":_vm.start,"load":_vm.start}})]),(_vm.cropper)?_c('div',{staticClass:"mx-auto toolbar",on:{"click":_vm.click}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"move","title":"Move (Crtl + M)"}},[_c('span',{staticClass:"fa fa-arrows-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"crop","title":"Crop (Crtl + C)"}},[_c('span',{staticClass:"fa fa-crop"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"zoom-in","title":"Zoom In (Crtl + I)"}},[_c('span',{staticClass:"fa fa-search-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"zoom-out","title":"Zoom Out (Crtl + O)"}},[_c('span',{staticClass:"fa fa-search-minus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"rotate-left","title":"Rotate Left (Crtl + L)"}},[_c('span',{staticClass:"fas fa-undo"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"rotate-right","title":"Rotate Right (Crtl + R)"}},[_c('span',{staticClass:"fa fa-undo fa-flip-horizontal"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"flip-horizontal","title":"Flip Horizontal (Crtl + H)"}},[_c('span',{staticClass:"fa fa-arrows-alt-h"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"toolbar__button",attrs:{"type":"button","data-action":"flip-vertical","title":"Flip Vertical (Crtl + Y)"}},[_c('span',{staticClass:"fa fa-arrows-alt-v"})])
}]

export { render, staticRenderFns }