import request from '@/utils/request-biometric'
export function search (params) {
  return request({
    url: '/bio-location/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/bio-location/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function createLocation (data) {
  return request({
    url: 'bio-location',
    method: 'post',
    data
  })
}

export function getLocation (id) {
  return request({
    url: `bio-location/${id}`,
    method: 'get'
  })
}

export function updateLocation (data, id) {
  return request({
    url: `bio-location/${id}`,
    method: 'put',
    data
  })
}

export function deleteLocation (id) {
  return request({
    url: `bio-location/${id}`,
    method: 'delete'
  })
}

export function validateLocationKey (keyLocation, config) {
  return request({
    url: 'bio-location/key/verify',
    method: 'post',
    params: {
      key: keyLocation
    }
  })
}

export function exportExcel (data) {
  return request({
    url: 'bio-location/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
