import Vue from 'vue'
// var moment = require('moment')

// Vue.use(moment)

import moment from 'moment'
moment.locale('es-MX')
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY/MM/D')
  }
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('D/MM/YYYY, HH:mm:ss ')
  }
})

Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value)).format('LTS')
  }
})
// Vue.filter('formatDateRegister', function (value) {
//   if (value) {
//     return moment(String(value), ['YYYY/MM/DD', 'MM/DD/YYYY'])
//   }
// })
