import request from '@/utils/request-biometric'
export function search (params) {
  return request({
    url: '/bio-license/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/bio-license/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function createLicense (data) {
  return request({
    url: 'bio-license',
    method: 'post',
    data
  })
}

export function getLicense (id) {
  return request({
    url: `bio-license/${id}`,
    method: 'get'
  })
}

export function updateLicense (data, id) {
  return request({
    url: `bio-license/${id}`,
    method: 'put',
    data
  })
}

export function deleteLicense (id) {
  return request({
    url: `bio-license/${id}`,
    method: 'delete'
  })
}

export function getTypes () {
  return request({
    url: '/bio-license/types',
    method: 'get'
  })
}

export function validateLicenseKey (keyLicense, config) {
  return request({
    url: 'bio-license/key/verify',
    method: 'post',
    params: {
      key: keyLicense
    }
  })
}

export function exportExcel (data) {
  return request({
    url: 'bio-license/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
