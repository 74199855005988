import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'
// import axios from 'axios'
import { validateLocationKey } from '@/api/bioLocations.js'
import { validateTerminalKey } from '@/api/bioTerminals.js'
import { validateLicenseKey } from '@/api/bioLicenses.js'
import {
  getValidateCollectionTemplate
} from '@/api/collectionTemplate'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('key', {
  validate: async (value, args) => {
    console.log(args)
    const params = {
      key: value
    }
    if (args._id !== undefined) { params._id = args._id }

    const valid = await getValidateCollectionTemplate(params).then(response => {
      return response.data
    })
    return valid
  },
  message: 'This key Exist',
  params: ['_id']
})

extend('https', {
  validate: value => {
    const regexp = new RegExp(/^(https:\/\/)([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/:?=&#]{1}[\da-z.-]+)*[/?]?$/, 'i')

    return regexp.test(value)
  },
  message: 'The {_field_} field must be a valid URL secure'
})

extend('http', {
  validate: value => {
    const regexp = new RegExp(/^(https?:\/\/)?([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/:?=&#]{1}[\da-z.-]+)*[/?]?$/, 'i')

    return regexp.test(value)
  },
  message: 'The {_field_} field must be a valid URL'
})

let messageResponse = ''
let validResponse = false

extend('uniqueLocationKey', {
  validate: (value) => {
    validateLocationKey(value)
      .then((response) => {
        console.log(response)
        messageResponse = response.data.message[0]
        validResponse = response.data.valid
      })
      .catch(err => console.log(err))
    return validResponse
  },
  message: () => {
    return messageResponse
  }
})

extend('uniqueTerminalKey', {
  validate: (value) => {
    validateTerminalKey(value)
      .then((response) => {
        console.log(response)
        messageResponse = response.data.message[0]
        validResponse = response.data.valid
      })
      .catch(err => console.log(err))
    return validResponse
  },
  message: () => {
    return messageResponse
  }
})

extend('uniqueLicenseKey', {
  validate: (value) => {
    validateLicenseKey(value)
      .then((response) => {
        console.log(response)
        messageResponse = response.data.message[0]
        validResponse = response.data.valid
      })
      .catch(err => console.log(err))
    return validResponse
  },
  message: () => {
    return messageResponse
  }
})

extend('regexKey', {
  validate: value => {
    const regexp = new RegExp(/^([a-zA-Z0-9](_?|-?))+([a-zA-Z0-9])$/)

    return regexp.test(value)
  },
  message: 'Solo se aceptan los siguientes caracteres (a..z, A..Z, 0..9, _ y -)'
})

extend('regexLocationKey', {
  validate: value => {
    const regexp = new RegExp(/^([A-Z0-9](_?|-?| ?))+([A-Z0-9])$/)

    return regexp.test(value)
  },
  message: 'Solo se aceptan Mayúsculas y sin carácteres especiales (A..Z, 0..9, _ y -)'
})

extend('stringMax255', {
  validate: value => {
    const regexp = new RegExp(/^(?!\s)(?!.*\s{2})[^\r\n]{1,255}(?<!\s)$/)

    return regexp.test(value)
  },
  message: 'No debe contener espacios al inicio o al final y se permite un máximo de 255 caracteres'
})

extend('serialFormat', {
  validate: value => {
    const regexp = new RegExp(/^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/)

    return regexp.test(value)
  },
  message: 'Ingrese únicamente caracteres (a..z, A..Z, 0..9 y -)'
})

extend('serialFormatMAX39', {
  validate: value => {
    const regexp = new RegExp(/^(?=.{39}$)[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/)

    return regexp.test(value)
  },
  message: 'El serial debe contener 39 caractéres, y caracteres (a..z, A..Z, 0..9 y -)'
})

extend('ipAddressFormat', {
  validate: value => {
    const regexp = new RegExp(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)

    return regexp.test(value)
  },
  message: 'No es una dirección IP válida'
})

extend('regexObjectKey', {
  validate: value => {
    const regexp = new RegExp(/^[a-zA-Z]+(?:[a-zA-Z0-9]+)+(?:-[a-zA-Z0-9]+)*$/)

    return regexp.test(value)
  },
  message: 'No se aceptan números como primer caracter ni espacios'
})
// extend('required', {
//   ...required,
//   message: 'This field is required'
// })

// extend('email', {
//   ...email,
//   message: 'This field must be a valid email'
// })

// extend('confirmed', {
//   ...confirmed,
//   message: 'This field confirmation does not match'
// })

// extend('length', {
//   ...length,
//   message: 'This field must have 2 options'
// })
